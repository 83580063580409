import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setOpenBookingWindow } from '../../../redux/actions/Booking/BookingAction';
import BookClass from './BookClass';
import { refreshUrl } from '../../../components/Utilities';
import Check from '../../../components/icon/Check';
import writingIcon from '../../../../assets/image/writing.png';
import speakingIcon from '../../../../assets/image/mic.png';
import readingIcon from '../../../../assets/svg/reading.svg'
import Volume from '../../../components/icon/Volume';
import Key from '../../../components/icon/Key';
import ReactHtmlParser from 'react-html-parser';
import { Button } from '@material-ui/core';
import Modal from '../Dialog/Modal';
import lock from '../../../../assets/image/icon-key.png';
import Help from '../../../../assets/image/icon-help.png';

import '../../../../sass/private/MyCourse/LessonDetails.scss';
import { getColorBorlineByScore } from '../../../../utils/helper';
import practiceApi from '../../../redux/services/practiceApi';
import { updateHasListenLesson } from '../../../redux/actions/MyCoursePage/CourseDetailsPageActions';
import { useSocket } from "../../../hooks/HomePage/useSocket";
import bookingWindowApi from '../../../redux/services/bookingWindowApi';

const LessonDetails = (props) => {
  const {courseDetails, onChangeCentre, onClickAudio, isTablet, centreId, classes, isPracticeModal, handleClosePracticeModal, setCourseId, setCourseType, getData,isOpenProgressDetail} = props
  const history = useHistory();
  const dispatch = useDispatch();


  const icon = courseDetails?.icon
  const activitiesAudio = courseDetails?.post_class_activities?.audio
  const activitiesLesson = courseDetails?.post_class_activities?.lti_activities
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState(null)
  const [checkedListen, setCheckedListen] = useState(courseDetails?.hasListenAudio);
  const showMorePractice = false;
  const [message, setMessage] = useState("");

  const [showPopover, setShowPopover] = useState(false);
  const toggleClass = () => {
    setShowPopover(!showPopover);
  }

  const renderIcon = () => {
    if(icon === "speaking") {
      return <img src={speakingIcon} alt=""/>
    } else if (icon === "writing") {
      <img src={writingIcon} alt=""/>
    } else {
      <img src={readingIcon} alt=""/>
    }
  }
  const handleClickOpen = (link) => {
    setLink(refreshUrl(link));
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    getData();
  };


  const openBookingWindow = (lessonTimeId) => {
    const pathname = history.location.pathname;
    const pathnameSplit = pathname.split('/')?.[1];
    history.push(`/${pathnameSplit}/${lessonTimeId}`);
    dispatch(setOpenBookingWindow(true));
  }
  
  const submitCheckedListen = () => {
    let isChecked = !checkedListen;
    setCheckedListen(isChecked);
    practiceApi.submitHasListenPractice(courseDetails?.lesson_id).then((result) => {
      dispatch(updateHasListenLesson(isChecked));
    })
  }
  
  const goBackBrowser = () => {
    history.go(-1);
  }

  useEffect(() => {
    window.scrollTo(0,0);
  }, [])

  const isLessonAttended = courseDetails?.has_attended === 1;

  const bookClass = () => {
    if(!isPracticeModal) {
      return (
        <BookClass
          aim={courseDetails?.aim}
          listCentres={courseDetails?.booking?.centres}
          centreId={centreId}
          classes={courseDetails?.booking?.classes}
          onChangeCentre={onChangeCentre}
          isAttended={isLessonAttended}
          openBookingWindow={openBookingWindow}
        />
      )      
    }
  };

  //update socket
  useEffect(() => {
    if (isOpenProgressDetail == false) {
      getData();
    }
  }, [isOpenProgressDetail]);
  
  useSocket({
    callBack: (payload) => {
      // reloadData()
      getData()
      // alert(payload.message);
      setMessage(payload.message);
    }
  });

  const handleClickButton = async(id) => {
    try {
      bookingWindowApi
      .getLessonFile(id)
    } catch (error) {
        console.log(error.message)
    }
  }

  return (
    <div className={`LessonDetails ${classes}`}>
    {!isTablet && <>
      <div className="header">
        <div className="header-top">
          <span>{courseDetails?.heading}</span>
          {renderIcon()}
        </div>
        <div className={"header-title-container" + (isLessonAttended? ' lesson-taken': '')}>
          <h1 className="header-title">{ReactHtmlParser(courseDetails?.title)}</h1>
          {isLessonAttended && <Check />}
        </div>
        <div className="header-subtitle">{ReactHtmlParser(courseDetails?.aim)}</div>
        {/* <div className="header-name">About {courseDetails?.about_text || ""}</div>
        <div className="header-desc">
          {ReactHtmlParser(courseDetails?.description)}
          <br /> 
        </div> */}
      </div>

      {!isTablet && !isLessonAttended && bookClass()}
    </>}

      {isTablet &&
        <div className="post-classes">
          <div className="header-classes">
            <div className="header-classes__title">{ReactHtmlParser(courseDetails?.title)}</div>
            <div className="header-classes__date">{courseDetails?.lastAttendDateMobile}</div>
          </div>
        </div>
      }

      {courseDetails?.display_self_student && <div className="footer">
      {!isTablet && <>
        {/* <div className="footer-title">Self-study activities</div>
        <div className="footer-desc">These activities will help you review and practice the language you learned in the lesson.</div> */}
      </>}

        <div className="wrap-footer">
          {!isTablet && 
            <div className="practice-activities">
              <div className="left-practice-activities">
                <div className="header-practice">Practice activities</div>
                <div className="subheader">
                  These activities will help you review and practice<br></br> the language you learned in the lesson. 
                </div>
              </div>
              
              {activitiesLesson?.activities_count ? 
                (
                  <div className="right-practice-activities">
                    <div className="circle-score-practice" style={{"border": `3px solid ${activitiesLesson?.activities_joined_count >= activitiesLesson?.activities_count ? '#0ACF83' : ''}`}}>{activitiesLesson?.activities_joined_count}/{activitiesLesson?.activities_count}</div>
                    <p className='progress-score'>Progress</p>
                  </div> 
                ) : ''
              }
            </div> 
          }     

          {
            activitiesLesson?.has_activities === 1 &&
            <div className="lesson-review">
            <div className="review-header">
              {!isTablet &&
                <div className="review-header__left">Lesson review</div>
              }
              {
                activitiesLesson?.has_started > 0 ? 
                (
                  <>{!isTablet && <div className="review-header__right">
                    <div className="title-score">Score</div>
                    <div className="--score" style={{"border": `3px solid ${getColorBorlineByScore(activitiesLesson?.average_grade)}`}}>{activitiesLesson?.average_grade}</div>
                  </div>}</>
                ) : <>{!isLessonAttended && <Key className="locked" />}</>
              }
            </div>
            <div className="subheader">
              {!isTablet && <>
                {isLessonAttended?
                  <>Review what you have learned with these activities.<br />You can do them as many times as you like.</>: 
                  "You must attend the lesson to unlock the lesson review activities."
                }
                </>
              }
              {isTablet && activitiesLesson?.has_started > 0 &&
                <div className="review-header__right">
                  <div className="title-score">
                    <p className='title'>Lesson review</p>
                    <p className='subtitle'>Aggregate score</p>
                  </div>
                  <div className="--score" style={{"border": `3px solid ${getColorBorlineByScore(activitiesLesson?.average_grade)}`}}>{activitiesLesson?.average_grade}</div>
                </div>
              }
            </div>
            {
              activitiesLesson?.has_activities === 1 &&
              <div className="list-review">
                {
                  activitiesLesson?.items?.map((item) => (

                  <div key={item?.id} className="item-review" onClick={item?.has_started === 1? () => handleClickOpen(item.lti_activity_link): () => {}}>
                    <div className="item-review__left">
                      <div className="--skill">{item?.category}</div>
                      <div className="--activity-name">{item?.name}</div>
                    </div>

                    <div className="item-review__right">
                      {
                        item?.has_started === 1 ?
                        <>
                          {!isTablet &&
                            <div className='--progress'>
                              {
                                (item.grade) ? 'Score' : 'Not started'
                              }
                            </div>
                          }
                          <div className="--score" style={{"border": `3px solid ${getColorBorlineByScore(item?.grade)}`}}>{item.grade ? item.grade : ''}</div>
                          {!isTablet && <Button
                            className={`btn MuiButton-contained btn-default btn-${item.grade ? 'try-again' : 'start'}`}
                            onClick={() => handleClickOpen(item.lti_activity_link)}
                          >
                            {item.grade ? 'Try again' : 'Start'}
                          </Button>}
                        </>
                        : <div className="not-started">
                           {!isTablet && <div className="--text">not started</div>}
                            <div className="--circle"></div>
                          </div>
                      }
                      
                    </div>
                  </div>
                  ))
                }
              </div>
            }

          </div>
          }
          
          <div className="listen-again">
            <div className="header-listen">Listening activity</div>
            <div className="subheader">
              {!isTablet && <>
                {
                  (activitiesAudio?.has_audio === 0 && "This lesson has no audio.") ||
                  (activitiesAudio?.has_audio === 1 && isLessonAttended) &&
                  "Listen again to the audio from the lesson and follow the text in the transcript." ||
                  (activitiesAudio?.has_audio === 1 && !isLessonAttended) &&
                  "You must attend the lesson to access the lesson audio."
                }
                </>
              }
            </div>
          
            {
              activitiesAudio?.has_audio === 1 &&
              <div className="audio-list">
                {
                  activitiesAudio?.items?.map(item => (
                    <Button key={item?.id} className={"btn MuiButton-contained btn-default btn-audio" + (isLessonAttended? " enabled": "")} onClick={() => isLessonAttended ? onClickAudio(item.id, item.name) : ''}>
                      <Volume />
                      <span className="text">{item?.name || ""}</span>
                    </Button>
                ))
              }
              </div>
            }
          </div>
          <div className='review-materials review-materials-detail listen-again'>
          {courseDetails?.lesson_files.length !== undefined && courseDetails?.lesson_files !== null ?
            <div className="accordion-wrappert accordion-header">
              <h3 className='header-listen'>
                Review class materials
                <span className={`icon-help ${showPopover? ' active' : ''}`} onClick={toggleClass}>
                  <img src={Help} alt ="help"/>
                  <div className='show-popover'>
                    <div className='ttl'>Download your lesson files</div>
                    <div className='txt'>Get ready for class by download your lesson files. Your teachers will continue to provide paper copies in our teaching centres where preferred, but we hope that you can help us to reduce paper waste, protect our environment, and embrace a greener future.</div>
                  </div>
                </span>
              </h3>
              {courseDetails?.lesson_files.length > 0 ?
              <div className='accordion-content accordion-content-details'>
                {courseDetails?.lesson_files.map((item, index) => {
                  return  <div key={index} className='accordion-review-inner'>
                      <div className='accordion-left'>
                          <p className='ttl'>{item.title}</p>
                          <p className='sub-ttl'>{item.subTitle}</p>
                      </div>
                      <div className='accordion-right'>
                        {!item.url == "" ?
                          <a href={item.url} className={`btn-download ${item.url == "" ? "download-key" : ""}`} target="_blank" onClick={() => handleClickButton(item.id)}>Download<img src={lock} alt="" /> </a>
                          : <a  className={`btn-download ${item.url == "" ? "download-key" : ""}`}>Download<img src={lock} alt="" /> </a>
                        }
                      </div>
                  </div>
                })}
              </div>
              : (<p className="no-lesson accordion accordion-content">There are no class materials for this lesson.</p>)}
            </div>
          : ("")}
          </div>
          
          <div className="listened">
            <div className="form">
              {activitiesAudio?.has_audio === 1 &&               
                <div className='form-submit-listented'>
                  <span className='title'>Have you listened to the audio?</span>
                  <div className={`checkbox ${checkedListen ? 'active': ''}`} onClick={submitCheckedListen}><div className="rate-list-items"></div></div>
                </div>
              }
            </div>
            <div className='close'>
              {(isPracticeModal) && 
                <Button onClick={handleClosePracticeModal} className={"btn MuiButton-contained btn-default btn-close"}>
                  <span className="text">Close</span>
                </Button>
              }
              {isTablet && 
                <div onClick={goBackBrowser} className='close-mobile'>Close</div>
              }          
            </div>
          </div>

          {showMorePractice &&
            <div className="more-practice">
              <div className="practice-header">
                <div className="practice-header__left">
                  <div className="--text">More practice</div>
                  <div className="--subtext">For advanced only</div>
                </div>
              </div>
            </div>
          }
        </div>

        
      </div>}

      {!isTablet && isLessonAttended && bookClass()}

      <Modal
        open={open}
        handleCloseDialog={handleCloseDialog}
      >
        <Button 
          variant="contained" 
          onClick={handleCloseDialog} 
          className="btn btn-return btn-top"
        >Close</Button>
        <div className="external-box">
          <iframe title="newframe" src={link} width="100%" height="100%"/>
        </div>
      </Modal>
    </div>
  )
}

export default LessonDetails;