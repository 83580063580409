import { memo, useState, useEffect, useRef, Fragment  } from 'react';
import useBookingPage from '../../../hooks/BookingPage/useBookingPage';
import arrow from '../../../../assets/svg/accordion-arrow-green.svg';
import arrowMobile from '../../../../assets/svg/accordion-arrow-blue.svg';
import '../../../../sass/private/Booking/Accordion.scss';
import ReactHtmlParser from 'react-html-parser';
import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';
import lock from '../../../../assets/image/icon-key.png';
import Help from '../../../../assets/image/icon-help.png';
import CustomCircularProgress from '../Common/CircularProgress';
import bookingWindowApi from '../../../redux/services/bookingWindowApi';

const AccordionReview = (props) => {
  const {
    data: { id, active, item, reViewClass},
    onChangeAccordion
  } = props;
  const {
    bookingWindowData,
    isLoading
  } = useBookingPage(props.reloadData);

  

  const { isTablet } = useCheckMobileTablet();
  const [showPopover, setShowPopover] = useState(false);
    const toggleClass = () => {
      setShowPopover(!showPopover);
    }

  const handleClickButton = async(id) => {
    try {
      bookingWindowApi
      .getLessonFile(id)
    } catch (error) {
        console.log(error.message)
    }
  }

  

  return (
    <div>
       {isLoading ? <CustomCircularProgress/> : (
        <div className={`accordion ${!active ? 'active' : 'not-active '}`}>
          {bookingWindowData?.review_class_materials !== undefined && bookingWindowData?.review_class_materials !== null ?
          <div>
          <div className="accordion-header" >
            <div>
              <h3>Review class materials
                <span className={`icon-help ${showPopover? ' active' : ''}`} onClick={toggleClass}>
                  <img src={Help} alt ="help"/>
                  <div className='show-popover'>
                    <div className='ttl'>Download your lesson files</div>
                    <div className='txt'>Get ready for class by download your lesson files. Your teachers will continue to provide paper copies in our teaching centres where preferred, but we hope that you can help us to reduce paper waste, protect our environment, and embrace a greener future.</div>
                  </div>
                </span>
              </h3>
            </div>
            <div onClick={() => onChangeAccordion(id)}>
              {isTablet && <img className="arrow-icon" src={arrowMobile} alt="Toggle" tabIndex={0} onKeyPress={() => onChangeAccordion(id)} />}
              {!isTablet && <img className="arrow-icon" src={arrow} alt="Toggle" tabIndex={0} onKeyPress={() => onChangeAccordion(id)} />}
            </div>
          </div>
          {bookingWindowData?.review_class_materials?.length > 0 ?
          <div className="accordion-content">
          {bookingWindowData?.review_class_materials.map((item, index) => {
            return <div key="index" className='accordion-review-inner'>
              <div className='accordion-left'>
                  <p className='ttl'>{item.title}</p>
                  <p className='sub-ttl'>{item.subTitle}</p>
              </div>
              <div className='accordion-right'>
                {!item.url == "" ?
                <a href={item.url} className={`btn-download ${item.url == "" ? "download-key" : ""}`} target="_blank" onClick={() => handleClickButton(item.id)}>Download<img src={lock} alt="" /> </a> 
                : <a  className={`btn-download ${item.url == "" ? "download-key" : ""}`}>Download<img src={lock} alt="" /> </a>
                }
              </div>
            </div> 
          })}
          </div>
          : (<p className="no-lesson accordion accordion-content">There are no class materials for this lesson.</p>) }
        </div>
        : ("") }
        </div>
       )}
    </div>
  )
}

export default AccordionReview;