import PrivatePage from "../../../components/PrivatePage";
import '../../../../sass/private/CommunityPage/CommunityPage.scss';
import { getRoute, getString } from '../../../components/Utilities';
import commmunityBanner from '../../../../assets/image/resolution-turkey.jpg';
import {  useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import useModule from "../../../hooks/HomePage/useModule";
import homeApi from "../../../redux/services/homeApi";
import { useQueryString } from '../../../../utils/useQueryString';
import Page from "../../../components/Page";
import Logo from '../../../../assets/image/british_council_english_white.svg';


const UnsubscribeEmail = (props) => {

  const [unsubscribeCode, setUnsubscribeCode] = useQueryString("unsubscribeCode", null)
  


  const [message, setMessage] = useState(null);
  const [unsubscribeName, setUnsubscribeName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect (() => {
    getUnsubscribeName(unsubscribeCode)
  },[])

  const getUnsubscribeName = async (unsubscribeCode) => {
    try {
      await homeApi
        .getUnsubscribeName(unsubscribeCode)
        .then((result) => {     
          setUnsubscribeName(result.data.notificationName ?? null);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error.message)
    }
  }

  const handleConfirmClick = async () => {
    try {
        homeApi
          .unsubscribeEmail(unsubscribeCode)
          .then((result) => {        
            if(result.success == true){
              setMessage('Your notification settings have been updated.')
            }
            setIsLoading(false);
          })
          .catch((e) => {
            setIsLoading(false);
          });
      } catch (err) {
          console.log(err.message)
      }
  }

  const handleCancelClick = async () => {
    setMessage('Your notification settings have not been updated.')
  }

  const { history, handleSearchClick } = props
      const onClickLogo = () => {
        history.push(getRoute("HOME"))
      }
  
  return (
    <Page  className={"coursemap-page unsubscribe-page" + getString(props.className)}>
      <header className="header">
          <div className="header__left">
            <img className="img-logo" src={Logo} alt="British Council" onClick={onClickLogo} />
          </div>
          {/* <div className="header__right" onClick={onClickLogo}>
              Go to British Council English
          </div> */}
         
        </header>
        <div className="unsubscribe-content">
          <div className="unsubscribe-content-wp">
            <h2 className="ttl-2">Please update my notification settings ...</h2>
            {(message == null || message == "") && (
              <>
            <p class="txt-receiving">I would like to stop receiving these notifications by email:</p>
          
              
              {unsubscribeName != null && (
                <button type="button" className="btn-completion btn" >{unsubscribeName}</button>
              )}
              <div className="inner-btn-unsubscribe">
                <button type="button" className="btn btn-confirm" onClick={handleConfirmClick}>Confirm</button>
                <button type="button" className="btn btn-cancel" onClick={handleCancelClick}>Cancel</button>
              </div>
              </>
            )}
            {(message != null && message !="") && (
              <p className="show-message">{message}</p>
            )}
            <p className="preferences">You can update all notification preferences in your <button type="button" onClick={onClickLogo}>communication settings.</button></p>
          </div>
        </div>
  
    </Page>
  )
}

export default UnsubscribeEmail;